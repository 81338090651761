import axios from "axios";

// 正式环境-旧道闸
axios.defaults.baseURL = "http://api.uxianxin.com/api";
// 正式环境-新道闸
// axios.defaults.baseURL = "http://106.15.230.82:9079/parking-api";
// 测试环境-新道闸
// axios.defaults.baseURL = "http://121.236.175.200:9089/parking-api";
// axios.defaults.baseURL = "http://127.0.0.1:9079/parking-api";

//简单封装
axios.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      // const { code, result, msg } = response.data;
      // if (code == 200) {
      //   return result;
      // }
      // return Promise.reject(msg);
      return response.data;
    } else {
      return Promise.reject("请求失败");
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  //支付类型
  GetPayTypeCount() {
    return axios.get("/NameValue/GetPayTypeCount");
  },
  //当日营收排行
  GetMoneyRank() {
    return axios.get("/NameValue/GetMoneyRank");
  },
  //今日总
  GetTodayAllMoney() {
    return axios.get("/NameValue/GetTodayAllMoney");
  },
  //减免
  GetTodayFreeMoney() {
    return axios.get("/NameValue/GetTodayFreeMoney");
  },
  //今日交易笔数
  GetTodayAllOrderNum() {
    return axios.get("/NameValue/GetTodayAllOrderNum");
  },
  //本月上月金额
  GetAllMoneyOneMonth() {
    return axios.get("/NameValue/GetAllMoneyOneMonth");
  },
  //车辆总数
  GetTotalCarNum() {
    return axios.get("/NameValue/GetTotalCarNum");
  },
  //用户总数
  GetTotalUserNum() {
    return axios.get("/NameValue/GetTotalUserNum");
  },
  //道闸在线
  GetOnlineCameraNum() {
    return axios.get("/NameValue/GetOnlineCameraNum");
  },
  //道闸离线
  GetOfflineCameraNum() {
    return axios.get("/NameValue/GetOfflineCameraNum");
  },
  //岗亭在线
  GetOnlineStationNum() {
    return axios.get("/NameValue/GetOnlineStationNum");
  },
  //岗亭离线
  GetOfflineStationNum() {
    return axios.get("/NameValue/GetOfflineStationNum");
  },
  //7天
  GetLastWeekMoney() {
    return axios.get("/NameValue/GetLastWeekMoney");
  },

   //支付类型
   GetPayTypeCount2() {
    return axios.get("/AnJieValue/GetPayTypeCount");
  },
  //当日营收排行
  GetMoneyRank2() {
    return axios.get("/AnJieValue/GetMoneyRank");
  },
  //今日总
  GetTodayAllMoney2() {
    return axios.get("/AnJieValue/GetTodayAllMoney");
  },
  //减免
  GetTodayFreeMoney2() {
    return axios.get("/AnJieValue/GetTodayFreeMoney");
  },
  //今日交易笔数
  GetTodayAllOrderNum2() {
    return axios.get("/AnJieValue/GetTodayAllOrderNum");
  },
  //本月上月金额
  GetAllMoneyOneMonth2() {
    return axios.get("/AnJieValue/GetAllMoneyOneMonth");
  },
  //车辆总数
  GetTotalCarNum2() {
    return axios.get("/AnJieValue/GetTotalCarNum");
  },
  //用户总数
  GetTotalUserNum2() {
    return axios.get("/AnJieValue/GetTotalUserNum");
  },
  //道闸在线
  GetOnlineCameraNum2() {
    return axios.get("/AnJieValue/GetOnlineCameraNum");
  },
  //道闸离线
  GetOfflineCameraNum2() {
    return axios.get("/AnJieValue/GetOfflineCameraNum");
  },
  //岗亭在线
  GetOnlineStationNum2() {
    return axios.get("/AnJieValue/GetOnlineStationNum");
  },
  //岗亭离线
  GetOfflineStationNum2() {
    return axios.get("/AnJieValue/GetOfflineStationNum");
  },
  //7天
  GetLastWeekMoney2() {
    return axios.get("/AnJieValue/GetLastWeekMoney");
  },

  //支付类型
  GetPayTypeNum(param,param2) {
    return axios.get("/idata/selectPayTypeNum?organId="+param + "&roleIds="+param2);
  },
  //当日营收排行
  GetDataStatistics(param,param2) {
    return axios.get("/idata/selectDataStatistics?organId="+param + "&roleIds="+param2);
  },
  //近7日营收统计
  GetSevenStatistics(param,param2) {
    return axios.get("/idata/selectSevenStatistics?organId="+param + "&roleIds="+param2);
  },
  //当日排名
  GetProfitRanking(param,param2) {
    return axios.get("/idata/selectProfitRanking?organId="+param + "&roleIds="+param2);
  },
  // 今日营业数据
  GetTodayBusinessData(param,param2) {
    return axios.get("/idata/selectBusinessData?organId="+param + "&roleIds="+param2);
  },
  // 道闸在线
  GetCameraState(param,param2) {
    return axios.get("/idata/selectCameraState?organId="+param + "&roleIds="+param2);
  },
};
