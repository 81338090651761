<template>
  <div id="top-header">
    <div class="left">
      <template v-if="anjie">
        <div class="title-img">
          <img src="../../../assets/logo_anjie.jpg" alt="" />
          <div class="title" style="margin-left: 48px">车辆管理运营数据大屏</div>
        </div>
      </template>
      <template v-else>
        <div class="title-img2">
          <img src="../../../assets/title.png" alt="" />
          <div class="title" style="margin-left: 48px">优安停运营数据大屏</div>
        </div>
      </template>
    </div>
    <div class="right" @click="changeFullScreen">
      <div class="time">{{ nowtime }}</div>
      <span class="nowdate">{{ nowdate }}</span>
      <img src="../../../assets/header-icon.png" alt="" class="arrow-right" />
      <div class="week">{{ week }}</div>
      <img src="../../../assets/icon-full.png" class="full-icon" alt="" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import handleGo from "../../../common/common";
export default {
  name: "TopHeader",
  data() {
    return {
      date: "",
      time: "",
      nowdate: "", //日期
      nowtime: "", //时间
      newTimer: "", //定时器
      weekArr: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      week: "",
      fullscreen: false,
      fullName: "全屏",
    };
  },
  props: {
    anjie: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // moment.locale("zh-cn");
    // this.date = moment().format("YYYY-MM-DD dddd");
    // this.time = moment().format("HH:ss");
    // console.log(this.date);
    this.setGif();
  },
  methods: {
    //获取时间
    timerOneScondRun() {
      let date = new Date();
      // 日期格式  2022-08-25 星期四
      this.nowdate = moment().format("YYYY-MM-DD");
      // 时间格式 12:11:27
      this.week = this.weekArr[date.getDay()];
      this.nowtime = moment().format("HH:mm:ss");
      //十分钟emit一次
      if (new Date().getMinutes() % 10 == 0 && new Date().getSeconds() == 0) {
        this.$emit("reload", this.nowtime);
      }
    },
    // 序列帧
    setGif() {
      let ht = "";
      for (let i = 1; i < 51; i++) {
        let a = i < 10 ? `0${i}` : i;
        ht += `
			${2 * i}% {
				background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/title/000${a}.png");
			}
		`;
      }
      let keyframes = `
		    @keyframes run-gif{
			${ht}
	  	}
	`;
      //给body添加动画css
      var sheet = document.styleSheets[0];
      sheet.insertRule(keyframes, 0);
    },
    changeFullScreen() {
      const element = document.documentElement;
      // 如果是全屏状态
      if (this.fullscreen) {
        // 如果浏览器有这个Function
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 如果浏览器有这个Function
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      // 判断全屏状态的变量
      this.fullscreen = !this.fullscreen;
      this.fullName = this.fullscreen ? "退出全屏" : "全屏";
    },
  },
  mounted() {
    this.timerOneScondRun(); //执行日期函数
    clearInterval(this.newTimer); //清除定时器
    // 定时获取时间
    this.newTimer = setInterval(this.timerOneScondRun, 1000);
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存
    clearInterval(this.newTimer);
    next();
  },
};
</script>

<style lang="less" scoped>
#top-header {
  display: flex;
  align-items: center;
  height: 192px;
  padding: 0 72px;
  // background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/title/00001.png");
  background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/title/00001.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00002.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00003.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00004.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00005.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00006.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00007.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00008.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00009.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00010.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00011.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00012.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00013.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00014.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00015.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00016.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00017.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00018.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00019.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00020.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00021.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00022.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00023.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00024.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00025.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00026.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00027.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00028.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00029.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00030.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00031.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00032.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00033.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00034.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00035.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00036.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00037.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00038.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00039.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00040.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00041.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00042.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00043.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00044.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00045.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00046.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00047.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00048.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00049.png"),
    url("https://cdn.uxianxin.com/tingLargeSrceen/title/00050.png");
  animation: run-gif 2s steps(1, end) infinite;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  .title,
  .left {
    font-size: 76px;
    font-family: YouSheBiaoTiHei;
    color: #eff4ff;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    .title-img {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }
    }
    .title-img2 {
      padding: 22px 46px 14px 48px;
      // width: 270px;
      height: 80px;
      border-radius: 51px;
      border: 2px solid #a0dfe0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 176px;
        height: 48px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;

    .time {
      display: flex;
      align-items: center;
      color: #acc9e6;
      font-size: 56px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #acc9e6;
      line-height: 80px;
    }
    .nowdate,
    .week {
      display: flex;
      align-items: center;
      font-size: 36px;
      font-family: SFProText-Regular, SFProText;
      font-weight: 400;
      color: #acc9e6;
      line-height: 42px;
      margin-left: 48px;
    }
    .arrow-right {
      width: 60px;
      height: 38px;
      margin-left: 48px;
    }
    .full-icon {
      width: 48px;
      height: 50px;
      margin-left: 48px;
    }
  }
}
</style>
