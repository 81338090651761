<template>
  <div>
    <div :id="randomId" :style="chartStyle"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import china from "./china.json";
import suzhou from "./suzhou.json";
import wuzhong from "./wuzhong.json";
export default {
  name: "vue-echarts",
  props: {
    options: {
      type: Object,
      required: true,
    },
    chartStyle: {
      type: Object,
    },
  },
  data() {
    return {
      myChart: null,
      randomId: "charts" + Math.random(),
    };
  },
  watch: {
    options(newValue, oldValue) {
      if (this.myChart && newValue) {
        this.myChart.clear();
        this.myChart.setOption(newValue, true);
      }
    },
  },
  methods: {
    init() {
      if (!this.myChart) {
        echarts.registerMap("china", china); // 注册地图
        echarts.registerMap("suzhou", suzhou); // 注册地图
        echarts.registerMap("wuzhong", wuzhong); // 注册地图
        this.myChart = echarts.init(document.getElementById(this.randomId));
      }
      this.myChart.setOption(this.options, true);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
