<template>
  <div id="my-left">
    <div class="left card-container">
      <img src="../../../assets/img-today-total.png" alt="" class="img-title" v-if="flag" />
      <div v-else  class="top-header">
        <img src="../../../assets/img-today-total2.png" alt="" class="img-title" />
        <div class="header-right" @click.stop="goMore">
          <span class="text">查看更多</span>
           <img src="../../../assets/img-today-arrow-left.png" alt="" class="arrow" />
        </div>
      </div>
      <div class="item-containier">
        <div v-for="(item, index) in list" :key="index" class="item">
          <div class="item-title">
            <div>
              <span>No.{{ index + 1 }}</span>
              <span style="margin-left: 32px">{{ item.name.split("-")[0] }}</span>
            </div>
            <div
              class="price"
              :style="{
                color: index == 0 ? '#FF9563' : index == 1 ? '#4A96FD' : '',
              }"
            >
              {{ item.value }}元
            </div>
          </div>
          <div class="item-body">
            <div
              :class="index == 0 ? 'today1' : index == 1 ? 'today2' : 'today3'"
              :style="{ width: (item.value / list[0].value) * 85 + '%' }"
            ></div>
            <div class="item-end"></div>
          </div>
        </div>
      </div>
      <img src="../../../assets/top-right.png" alt="" class="top-right" />
      <img src="../../../assets/bottom-left.png" alt="" class="bottom-left" />
      <img src="../../../assets/bottom-right.png" alt="" class="bottom-right" />
    </div>
    <div class="right">
      <div class="status">
        <div class="status-item">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="title">今日营收总额</div>
          <div class="curr">￥</div>
          <div class="num">
            <count-to :startVal="oldTodayAllMoney" :endVal="newTodayAllMoney" :duration="1000"></count-to>
          </div>
        </div>
        <div class="status-item">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="title">今日减免总额</div>
          <div class="curr">￥</div>
          <div class="num">
            <count-to :startVal="oldTodayFreeMoney" :endVal="newTodayFreeMoney" :duration="1000"></count-to>
          </div>
        </div>
        <div class="status-item">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="title">今日交易笔数</div>
          <!-- <div class="curr">￥</div> -->
          <div class="num" style="margin-left: 40px">
            <count-to :startVal="oldTodayAllOrderNum" :endVal="newTodayAllOrderNum" :duration="1000"></count-to>
            <span style="font-size: 42px; font-weight: 500; margin-bottom: 4px">笔</span>
          </div>
        </div>
      </div>
      <!-- <el-amap ref="elAmap" class="amap-box" :vid="'amap-vue'" v-bind="mapConfig" :plugin="plugins"> ></el-amap> -->
      <div id="3dMap" style="width: 100%; height: 100%" v-if="anjie"></div>
      <div v-else>
        <div class="suzhou">
          <div class="title">江苏省苏州市</div>
          <div class="sub-title"><span>停车场数</span><span style="margin-left: 54px">{{TotalUserNum2[1].value }}</span></div>
        </div>
        <div class="china"></div>
        <div class="china2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "./echarts-base.js";
import VueEcharts from "../../../components/echartsBase/VueEcharts.vue";
import { handleGo, GetUrlParam } from "../../../common/common";
import CountTo from "vue-count-to";
import VueAMap from 'vue-amap';


VueAMap.initAMapApiLoader({
  key: "a03c9fab18b5cdcf67c054d2e68a5899",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation",
    "AMap.Geocoder",
    "AMap.MarkerClusterer",
    "AMap.Object3DLayer",
  ],
  uiVersion: "1.1", // ui库版本，不配置不加载,
  v: "1.4.4"
});
export default {
  name: "left",
  components: {
    VueEcharts,
    CountTo,
  },
  props: {
    moneyRank: Array,
    anjie: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTodayAllMoney: 0, //今日总
      newTodayFreeMoney: 0, //减免
      newTodayAllOrderNum: 0, //今日交易笔数
      oldTodayAllMoney: 0,
      oldTodayFreeMoney: 0,
      oldTodayAllOrderNum: 0,
      showMap: false,
      chartOptionA: {},
      chartOptionB: {},
      list: [],
      mapConfig: {
        center: [120.666088, 31.288165],
        zoom: 20,
        zooms: [17, 20],
        events: this.eventsFun(), // 地图加载完成时执行的方法
        // city: "苏州市", // 描边传参
        mask: [
          [120.654529, 31.283113],
          [120.65513, 31.289366],
          [120.655296, 31.29318],
          [120.668208, 31.292628],
          [120.671936, 31.290975],
          [120.676389, 31.288403],
          [120.674994, 31.284433],
        ],
        skyColor: "#030B20",
        animateEnable: false,
        disableSocket: false,
        mapStyle: "amap://styles/a66e191830673d14b76b4ce46a5a2496",
        features: ["bg", "road", "point"],
        viewMode: "3D",
        showBuildingBlock: true,
        pitch: 70,
        rotation: 50,
        Object3D: {
          Wall: {
            path: [
              [120.654529, 31.283113],
              [120.65513, 31.289366],
              [120.655296, 31.29318],
              [120.668208, 31.292628],
              [120.671936, 31.290975],
              [120.676389, 31.288403],
              [120.674994, 31.284433],
            ],
            height: 20,
            color: "#4F4F4F",
          },
        },
        Polyline: {},
      },
      TotalUserNum2: [
        {
          name: "",
          value: "",
        },
        {
          name: "",
          value: "",
        },
      ],
      markers: [],

      plugins: [
        "AMap.MapType",
        // "AMap.ToolBar",
        // "AMap.Geocoder",
        // "AMap.Geolocation",
      ],
      iconPoint: require("../../../assets/onLine.png"), // 标记点背景
      window: { visible: false }, // 消息窗体 数据
      showWindow: false, // 消息窗体显示隐藏
      map: null,
      flag: true,
    };
  },
  created() {
    this.setGif();
    if (GetUrlParam("name") == "anjie") {
      this.flag = false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.init3dMap();
    }, 500);
  },
  watch: {},
  methods: {
    goMore() {
      handleGo(document,"net", "/RMBase/ReportManager/ParkPayRank_List.aspx", "车场营收排名");
    },
    init3dMap() {
      const that = this;
      const path = [
        ["120.654529", " 31.283113"],
        ["120.65513", "31.289366"],
        ["120.655296", "31.29318"],
        ["120.668208", " 31.292628"],
        ["120.671936", " 31.290975"],
        ["120.676389", " 31.288403"],
        ["120.674994", "31.284433"],
      ];
      let buildingLayer = new AMap.Buildings({
        zIndex: 10,
        heightFactor: 2,
        merge: false,
        sort: false,
        zooms: [16, 18],
        zoom: 18,
        opacity: 1,
      });
      let options = {
        hideWithoutStyle: false, //是否隐藏设定区域外的楼块
        areas: [
          {
            //围栏1
            visible: true, //是否可见
            rejectTexture: false, //是否屏蔽自定义地图的纹理
            color1: "ee66ffff", //楼顶颜色
            color2: "4F75B6ff", //楼面颜色
            path: path,
          },
        ],
      };

      buildingLayer.setStyle(options); //此配色优先级高于自定义mapStyle
      this.map = new AMap.Map("3dMap", {
        zoom: 18,
        zooms: [18, 20],
        pitch: 50,
        mask: path,
        showLabel: true,
        rotation: 0,
        skyColor: "#030B20",
        animateEnable: false,
        disableSocket: false,
        mapStyle: "amap://styles/darkblue",
        // amap://styles/96c90653a58e38ae75120d033fee1ce7
        // a66e191830673d14b76b4ce46a5a2496
        // darkblue
        center: [120.665088, 31.288165],
        features: ["bg", "road", "point"],
        viewMode: "3D",
        showBuildingBlock: true,
        layers: [new AMap.TileLayer(), buildingLayer],
      });
      //添加高度面
      AMap.plugin(["AMap.Object3DLayer", "AMap.Object3D"], function () {
        let object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
        that.map.add(object3Dlayer);
        let height = 0;
        let color = "#4F4F4F"; //rgba
        let wall = new AMap.Object3D.Wall({
          path: path,
          height: height,
          color: color,
        });
        wall.transparent = true;
        object3Dlayer.add(wall);
      });

      //添加描边
      new AMap.Polyline({
        path: path,
        strokeColor: "#ccc",
        strokeWeight: 5,
        map: that.map,
      });
    },
    initTodayAllMoney(data) {
      this.oldTodayAllMoney = this.newTodayAllMoney;
      this.newTodayAllMoney = data;
    },
    initTodayFreeMoney(data) {
      this.oldTodayFreeMoney = this.newTodayFreeMoney;
      this.newTodayFreeMoney = data;
    },
    initTodayAllOrderNum(data) {
      this.oldTodayAllOrderNum = this.newTodayAllOrderNum;
      this.newTodayAllOrderNum = data;
    },
    // 序列帧
    setGif() {
      let ht = "";
      for (let i = 1; i < 51; i++) {
        let a = i < 10 ? `0${i}` : i;
        ht += `
			${2 * i}% {
				background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/china/000${a}.png");
			}
		`;
      }
      let keyframes = `
		    @keyframes run-gif-left{
			${ht}
	  	}
	`;
      //给body添加动画css
      var sheet = document.styleSheets[0];

      sheet.insertRule(keyframes, 0);
    },
    onChange() {
      // console.log("123123");
      this.showMap = !this.showMap;
      this.$emit("change");
    },
    initMapData() {
      let list = [];
      let icon = new AMap.Icon({
        image: location,
        size: new AMap.Size(120, 120),
        imageSize: new AMap.Size(120, 120),
      });

      const that = this;
      this.list2.forEach((item, index) => {
        list.push({
          position: [item.longitude, item.latitude],
          // icon: 'none', //不设置默认蓝色水滴
          label: {
            content: item.content,
            offset: item.offset,
          },
          events: {
            click() {
              console.log("123123");
              // that.windows.forEach((window) => {
              //   window.visible = false; //关闭窗体
              // });
              // that.window = that.windows[index];
              // that.$nextTick(() => {
              //   that.window.visible = true; //点击点坐标，出现信息窗体
              // });
            },
          },
        });
      });

      this.markers = list;
      console.log(this.markers);
      this.$nextTick(() => {
        setTimeout(() => {
          that.$refs.elAmap.$$getInstance().setFitView();
        }, 1000);
      });
    },
    eventsFun() {
      // const that = this;
      // return {
      //   // 地图加载完成时执行的方法
      //   complete() {
      //     that.initMapData();
      //   },
      // };
    },
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存

    next();
  },
};
</script>
<style>
.amap-marker .amap-icon {
  display: none;
}
.amap-marker-label {
  position: absolute;
  z-index: 2;
  border: 0px;
  background-color: transparent;
  width: 262px;
  height: 400px;
}
.amap-marker-label .marker {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.amap-marker-label .info {
  width: 262px;

  text-align: center;
  /* height: 130px; */
  color: #fff;
  font-size: 28px;
  height: 48px;
  line-height: 48px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/marker-title.png");
}
.amap-marker-label .top {
  width: 64px;
  height: 64px;
}
.amap-marker-label .bottom {
  margin-top: -30px;
  width: 120px;
  height: 110px;
}
</style>
<style lang="less" scoped>
#my-left {
  height: 1132px;
  display: flex;
  .left {
    width: 900px;
    height: 1132px;
    .item-containier {
      padding: 50px 64px;
      .item {
        height: 105px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a7d2ff;
        .item-title {
          display: flex;
          justify-content: space-between;
          height: 66px;
          .price {
            font-size: 40px;
            font-family: SFProDisplay-Bold, SFProDisplay;
            font-weight: bold;
            color: #e1faff;
          }
        }
        .item-body {
          width: 772px;
          height: 12px;
          box-sizing: border-box;
          display: flex;
          animation: animate-positive 2s;
          // background: linear-gradient(270deg, #16BDDA 0%, #096688 100%);
          .today1 {
            position: relative;
            background: linear-gradient(270deg, #ff8b54 0%, #805a3e 100%);
          }
          .today1::after,
          .today2::after,
          .today3::after {
            position: absolute;
            content: "";
            width: 66px;
            height: 66px;
            right: -33px;
            top: -27px;
          }
          .today1::after {
            background-image: url("../../../assets/rang-1.png");
          }
          .today2 {
            position: relative;
            background: linear-gradient(270deg, #4a96fd 0%, #1c5cb3 100%);
          }
          .today2::after {
            background-image: url("../../../assets/rang-2.png");
          }
          .today3 {
            position: relative;
            background: linear-gradient(270deg, #16bdda 0%, #096688 100%);
          }
          .today3::after {
            background-image: url("../../../assets/rang-3.png");
          }
        }
        .item-end {
          flex: 1;
          border: 2px solid #4558c933;
          height: 12px;
          box-sizing: border-box;
        }
      }
    }
  }
  .right {
    width: 100%;
    height: 1125px;
    position: relative;
    .suzhou {
      // width: 414px;
      padding: 0 70px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      font-size: 32px;
      top: 29%;
      right: 28.6%;
      position: absolute;
      background-image: url("../../../assets/pop-suzhou.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 99;

      .title {
        background: linear-gradient(180deg, #ffffff 0%, #f2da68 100%);
        background-clip: text;
        color: transparent;
        font-family: Tensentype-LingHeiJ;
        margin-top: 16px;
      }
      .sub-title {
        margin-top: 30px;
      }
      div {
        height: 36px;
      }
    }
    .status {
      position: absolute;
      font-size: 24px;
      opacity: 0.9;
      display: flex;
      z-index: 999;
      height: 214px;

      .status-item:nth-child(2) .dot {
        background: #409aff;
      }
      .status-item:nth-child(3) .dot {
        background: #2ea2bf;
      }
      .status-item {
        // overflow: hidden;
        width: 776px;
        height: 214px;
        display: flex;
        background-image: 100% 100%;
        background-repeat: no-repeat;
        padding-left: 238px;
        position: relative;

        // line-height: 214px;
        .title {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #dcedff;
          line-height: 250px;
        }
        .curr {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 260px;
          margin-left: 32px;
        }
        .num {
          font-size: 60px;
          font-family: SFProDisplay-Bold, SFProDisplay;
          font-weight: bold;
          line-height: 240px;
        }
        .dot,
        .dot,
        .dot,
        .dot,
        .dot {
          position: absolute;
          width: 10px;
          height: 10px;
          background: #ffcf52;
          border-radius: 50%;
          opacity: 0.8;
          left: 10%;
          bottom: 80px;
          animation: flying 4s linear infinite;
        }

        .dot:nth-child(1) {
          left: 6%;
          animation-duration: 2s;
          animation-delay: 1.1s;
          filter: blur(1.177625122669284px);
        }
        .dot:nth-child(2) {
          animation-duration: 3s;
          animation-delay: 1.2s;
          left: 8%;
          width: 14px;
          height: 14px;
          filter: blur(2.355250245338567px);
        }
        .dot:nth-child(3) {
          animation-duration: 2.5s;
          animation-delay: 1s;
          left: 12%;
          width: 13px;
          height: 13px;
          filter: blur(1.477625122669284px);
        }
        .dot:nth-child(4) {
          animation-duration: 2.8s;
          animation-delay: 1.6s;
          left: 14%;
          width: 12px;
          height: 12px;
          filter: blur(1.877625122669284px);
        }
        .dot:nth-child(5) {
          animation-duration: 2.3s;
          animation-delay: 1.7s;
          left: 17%;
          width: 11px;
          height: 11px;
          filter: blur(1.177625122669284px);
        }
      }
      .status-item:nth-child(1) {
        color: #ffc101;
        background-image: url("../../../assets/total-revenue.png");
      }
      .status-item:nth-child(2) {
        color: #409aff;
        background-image: url("../../../assets/total-relief.png");
      }
      .status-item:nth-child(3) {
        color: #08f4ff;
        background-image: url("../../../assets/total-num.png");
      }
    }
    .amap-box {
      width: 100%;
      height: 100%;
      margin-left: 48px;
      margin-top: 4px;
    }
    .back {
      position: absolute;
      width: 120px;
      height: 120px;
      bottom: 18px;
      right: 48px;
      background: rgba(13, 32, 92, 0.77);
      border-radius: 16px;
      border: 2px solid #02558e;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-top: 8px;
      }
      .back-name {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #b5d7fb;
        line-height: 40px;
        height: 40px;
      }
    }
    // background: red;
    // margin-top: 200px;
    // margin-left: -100px;
  }
}
@keyframes flying {
  0% {
    bottom: 80px;
  }

  50% {
    bottom: 200px;
  }
  100% {
    bottom: 240px;
  }
}
.china,
.china2 {
  // background-image: url("../../../assets/china/00001.png");
  // background: round;
  position: absolute;
  left: 0;
  right: 0;
  width: 3380px;
  height: 1162px;
  display: block;
  background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/china/base.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-x: -280px;
  background-position-y: 120px;
}

.china {
  background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/china/00001.png");
  // background-image: url("https://cdn.uxianxin.com/tingLargeSrceen/china/00001.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00002.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00003.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00004.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00005.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00006.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00007.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00008.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00009.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00010.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00011.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00012.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00013.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00014.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00015.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00016.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00017.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00018.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00019.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00020.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00021.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00022.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00023.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00024.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00025.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00026.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00027.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00028.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00029.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00030.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00031.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00032.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00033.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00034.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00035.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00036.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00037.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00038.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00039.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00040.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00041.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00042.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00043.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00044.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00045.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00046.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00047.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00048.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00049.png"),
  // url("https://cdn.uxianxin.com/tingLargeSrceen/china/00050.png");
  animation: run-gif-left 2s steps(1, end) infinite;
  z-index: 2;
}
</style>
