module.exports = {
  // 大屏回系统页面
  handleGo(document,type, url, name) {
    /* 
        type 区分平台 必填
        url 需要的地址 必填
        name 路由名称或其他 非必填
    */
    // const element = document.documentElement;
    // console.log(document)
    if (document.fullscreenElement !== null) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    window.parent.postMessage(
      {
        data: {
          code: "success",
          obj: {
            type: type,
            url: url,
            name: name,
          },
        },
      },
      "*"
    );
  },
  //获取地址栏文字
  GetUrlParam(paraName) {
    var url = document.location.toString();
    var arrObj = url.split("?");

    if (arrObj.length > 1) {
      var arrPara = arrObj[1].split("&");
      var arr;

      for (var i = 0; i < arrPara.length; i++) {
        arr = arrPara[i].split("=");

        if (arr != null && arr[0] == paraName) {
          return arr[1];
        }
      }
      return "";
    } else {
      return "";
    }
  },
};
