<template>
  <div id="my-bottom">
    <div class="item card-container">
      <img src="../../../assets/img-total.png" alt="" class="img-title" />
      <div class="total-container">
        <div class="total-item">
          <img src="../../../assets/total-1.png" mode="" />
          <div>
            <div class="title">本月总营收</div>
            <div class="sub-title">
              <span class="sub-title1" v-if="AllMoneyOneMonth[0].value">{{ AllMoneyOneMonth[0].value }}</span>
              <!-- <span style="font-weight: 400">元</span> -->
            </div>
          </div>
        </div>
        <div class="total-item">
          <img src="../../../assets/total-2.png" mode="" />
          <div>
            <div class="title">上月总营收</div>
            <div class="sub-title">
              <span class="sub-title1" v-if="AllMoneyOneMonth[1].value">{{ AllMoneyOneMonth[1].value }}</span>
              <!-- <span style="font-weight: 400">元</span> -->
            </div>
          </div>
        </div>
        <div class="total-item">
          <img src="../../../assets/total-3.png" mode="" />
          <div>
            <div class="title">平台总用户数</div>
            <div class="sub-title">
              <span class="sub-title2">{{ TotalUserNum[0].value }}</span>
              <!-- <span style="font-weight: 400">位</span> -->
            </div>
          </div>
        </div>
        <div class="total-item">
          <img src="../../../assets/total-4.png" mode="" />
          <div>
            <div class="title">平台总车场数</div>
            <div class="sub-title">
              <span class="sub-title2">{{ TotalUserNum[1].value }}</span>
              <!-- <span style="font-weight: 400">个</span> -->
            </div>
          </div>
        </div>
        <div class="total-item">
          <img src="../../../assets/total-5.png" mode="" />
          <div>
            <div class="title">平台总车辆数</div>
            <div class="sub-title">
              <span class="sub-title3">{{ TotalCarNum[0].value }}</span>
              <!-- <span style="font-weight: 400">辆</span> -->
            </div>
          </div>
        </div>
        <div class="total-item">
          <img src="../../../assets/total-6.png" mode="" />
          <div>
            <div class="title">平台总商户数</div>
            <div class="sub-title">
              <span class="sub-title3">{{ TotalCarNum[1].value }}</span>
              <!-- <span style="font-weight: 400">个</span> -->
            </div>
          </div>
        </div>
      </div>

      <img src="../../../assets/top-right.png" alt="" class="top-right" />
      <img src="../../../assets/bottom-left.png" alt="" class="bottom-left" />
      <img src="../../../assets/bottom-right.png" alt="" class="bottom-right" />
    </div>
    <div class="item card-container">
      <img src="../../../assets/img-seven-day.png" alt="" class="img-title" />
      <div style="margin-left: 20px; margin-top: 20px">
        <VueEcharts v-cloak :options="chartOptionA" :chartStyle="{ width: '860px', height: '580px' }" />
      </div>

      <img src="../../../assets/top-right.png" alt="" class="top-right" />
      <img src="../../../assets/bottom-left.png" alt="" class="bottom-left" />
      <img src="../../../assets/bottom-right.png" alt="" class="bottom-right" />
    </div>
    <div class="item card-container">
      <img src="../../../assets/img-today.png" alt="" class="img-title" />

      <div style="margin-left: 20px; margin-top: 20px">
        <VueEcharts v-cloak :options="chartOptionB" :chartStyle="{ width: '860px', height: '580px' }" />
      </div>

      <img src="../../../assets/top-right.png" alt="" class="top-right" />
      <img src="../../../assets/bottom-left.png" alt="" class="bottom-left" />
      <img src="../../../assets/bottom-right.png" alt="" class="bottom-right" />
    </div>
    <div class="item card-container">
     
       <img src="../../../assets/img-equipment.png" alt="" class="img-title"  v-if="flag"/>
      <div v-else  class="top-header">
        <img src="../../../assets/img-equipment2.png" alt="" class="img-title" />
        <div class="header-right" @click.stop="goMore">
          <span class="text">查看更多</span>
           <img src="../../../assets/img-today-arrow-left.png" alt="" class="arrow" />
        </div>
      </div>
      <div class="eq-contaniner">
        <div class="item" v-for="(item, index) in listB" :key="index">
          <img src="../../../assets/onLine.png" mode="" v-if="index % 2 == 0" />
          <img src="../../../assets/outLine.png" mode="" v-else />
          <!-- <img src="../../../assets/line-border.png" alt="" class="border"> -->
          <div class="name">{{ item.name }}</div>
          <div class="num-container">
            <span class="num" :style="{ color: index % 2 == 0 ? '#13B3E7' : '#DF5733' }">{{ item.num }}</span>
            <span style="color: #fff">个</span>
          </div>
        </div>
      </div>

      <img src="../../../assets/top-right.png" alt="" class="top-right" />
      <img src="../../../assets/bottom-left.png" alt="" class="bottom-left" />
      <img src="../../../assets/bottom-right.png" alt="" class="bottom-right" />
    </div>
  </div>
</template>

<script>
import base from "./echarts-base.js";
import VueEcharts from "../../../components/echartsBase/VueEcharts.vue";
import CountTo from "vue-count-to";
import { handleGo, GetUrlParam } from "../../../common/common";
export default {
  name: "left",
  data() {
    return {
      chartOptionA: {},
      chartOptionB: {},
      listB: [
        { name: "道闸在线", num: 0 },
        { name: "道闸离线", num: 0 },
        { name: "岗亭在线", num: 0 },
        { name: "岗亭离线", num: 0 },
      ],
      AllMoneyOneMonth: [
        {
          name: "",
          value: "",
        },
        {
          name: "",
          value: "",
        },
      ],
      TotalCarNum: [
        {
          name: "",
          value: "",
        },
        {
          name: "",
          value: "",
        },
      ],
      TotalUserNum: [
        {
          name: "",
          value: "",
        },
        {
          name: "",
          value: "",
        },
      ],
      flag:true
    };
  },
  components: {
    CountTo,
    VueEcharts,
  },

  created() {
     if (GetUrlParam("name") == "anjie") {
      this.flag = false;
    }
  },
  watch: {},
  methods: {
    goMore() {
      handleGo(document,"net", "/RMBase/DeviceManager/Camera_List.aspx", "道闸列表");
    },
    initA(data) {
      let temp = JSON.parse(JSON.stringify(base.opt1));
      // console.log(data, "---");
      const x = [];
      const y = [];
      data.forEach((item) => {
        x.push(item.x);
      });
      data.forEach((item) => {
        y.push(item.y);
      });
      temp.xAxis.data = x;
      temp.series[0].data = y;
      this.chartOptionA = Object.assign({}, temp);
    },
    initB(data) {
      let temp = JSON.parse(JSON.stringify(base.opt2));
      temp.series[0].data = data;
      this.chartOptionB = Object.assign({}, temp);
    },
  },
  mounted() {
    // console.log(base);
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存

    next();
  },
};
</script>

<style lang="less" scoped>
#my-bottom {
  height: 720px;
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  .item {
    width: 900px;
  }
  .total-container {
    margin: 84px 60px;
    display: flex;
    flex-wrap: wrap;
    height: 500px;
    justify-content: space-between;
  }
  .total-item {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    width: 372px;
    height: 118px;

    background-image: url("../../../assets/total-item-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a7d2ff;

    .title {
      margin-top: 16px;
      height: 44px;
      line-height: 44px;
    }
    .sub-title {
      line-height: 38px;
      margin-top: 4px;
      height: 38px;
      font-weight: bold;
      font-family: SFProDisplay-Bold, SFProDisplay;
    }
    .sub-title1 {
      color: #eeb88b;
    }
    .sub-title2 {
      color: #23d2d1;
    }
    .sub-title3 {
      color: #007cff;
    }
    img {
      margin-left: 12px;
      margin-right: 24px;
      width: 96px;
      height: 96px;
    }
  }
  .eq-contaniner {
    padding: 20px 80px 20px 58px;
    .item {
      background-image: url("../../../assets/line-border.png");
      background-size: 644px 40px;
      background-position-x: 112px;
      background-position-y: 50px;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      line-height: 100px;
      height: 100px;
      color: red;
      margin-top: 32px;
      font-size: 32px;
      .name {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a7d2ff;
        margin-left: 84px;
      }
      .num-container {
        display: flex;
        align-items: flex-end;
        width: 240px;
        justify-content: flex-end;
        .num {
          font-size: 40px;
          font-family: SFProDisplay-Bold, SFProDisplay;
          font-weight: bold;
          color: #13b3e7;
          margin-right: 8px;
        }
      }

      img {
        width: 226px;
        height: 148px;
      }
    }
  }
}
</style>
